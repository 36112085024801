<template>
    <div id="root" @mousedown="clickHandler">
        <NuxtLoadingIndicator />
        <main id="main">
            <slot />
        </main>
        <FooterDefault />
    </div>
</template>
<script setup lang="ts">
const clickHandler = useClickHandler();
</script>
